/*
 * @Autor: Baowang
 * @Date: 2024-04-23 14:31:28
 * @pageName: 
 * @LastEditTime: 2024-07-29 17:30:19
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name:"homepage",
    component: () => import('../views/homepage/homepage.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },{
    path: '/homeSearch',
    name:"homeSearch",
    component: () => import('../views/homeSearch/homeSearch.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },{
    path: '/linkes',
    name:"linkes",
    component: () => import('../views/links/links.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/login',
    name:"login",
    component: () => import('../views/login/login.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/register',
    name:"register",
    component: () => import('../views/login/register.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/bindwx',
    name:"bindwx",
    component: () => import('../views/login/bindWx.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/wxLogin',
    name:"wxLogin",
    component: () => import('../views/login/wxLogin.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/bindphone',
    name:"bindphone",
    component: () => import('../views/login/bindphone.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/newsList',
    name:"newsList",
    component: () => import('../views/news/newsList.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/newDetail',
    name:"newDetail",
    component: () => import('../views/news/newDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/about',
    name:"about",
    component: () => import('../views/about/about.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/qiyeDetail',
    name:"qiyeDetail",
    component: () => import('../views/common/qiyeDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/helpdetail',
    name:"helpdetail",
    component: () => import('../views/common/helpdetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/licaiDetail',
    name:"licaiDetail",
    component: () => import('../views/common/licaiDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/userAgree',
    name:"userAgree",
    component: () => import('../views/common/userAgree.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/privacy',
    name:"privacy",
    component: () => import('../views/common/privacy.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/swiperDetail',
    name:"swiperDetail",
    component: () => import('../views/common/swiperDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/utilities',
    name:"utilities",
    component: () => import('../views/utilities/utilities.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/examination',
    name:"examination",
    component: () => import('../views/examination/examination.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/startExamination',
    name:"startExamination",
    component: () => import('../views/examination/startExamination.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/statistics',
    name:"statistics",
    component: () => import('../views/statistics/statistics.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    },
    children:[
      {
        path:"/",
        name:"geren",
        component: () => import('../views/statistics/geren.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        },
      },
      {
        path:"/tuandui",
        name:"tuandui",
        component: () => import('../views/statistics/tuandui.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        },
      }
    ]
  },
  {
    path: '/accounting',
    name:"accounting",
    component: () => import('../views/accounting/accounting.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/accountingDetail',
    name:"accountingDetail",
    component: () => import('../views/accounting/accountingDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/educate',
    name:"educate",
    component: () => import('../views/educate/educate.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/buyCourse',
    name:"buyCourse",
    component: () => import('../views/buyCourse/buyCourse.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/noticeList',
    name:"noticeList",
    component: () => import('../views/notice/noticeList.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/noticeDetail',
    name:"noticeDetail",
    component: () => import('../views/notice/noticeDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/recommendedCourses',
    name:"recommendedCourses",
    component: () => import('../views/recommendedCourses/recommendedCourses.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/courseDetail',
    name:"courseDetail",
    component: () => import('../views/courseDetail/courseDetail.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    }
  },
  {
    path: '/students',
    name:"students",
    component: () => import('../views/students/students.vue'),
    meta: {
      keepAlive: false // 是否需要缓存
    },
    children:[
      {
        path: '/',
        name:"info",
        component: () => import('../views/students/info.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path: '/collections',
        name:"collections",
        component: () => import('../views/students/collections.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path: '/paymentRecords',
        name:"paymentRecords",
        component: () => import('../views/students/paymentRecords.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path: '/invoice',
        name:"invoice",
        component: () => import('../views/students/invoice.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path: '/messagelist',
        name:"messagelist",
        component: () => import('../views/message/messagelist.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path: '/addMessage',
        name:"addMessage",
        component: () => import('../views/message/addMessage.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      },
      {
        path: '/progress',
        name:"progress",
        component: () => import('../views/students/progress.vue'),
        meta: {
          keepAlive: false // 是否需要缓存
        }
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
