/*
 * @Autor: Baowang
 * @Date: 2023-07-03 17:39:29
 * @pageName: 配置域名文件
 * @LastEditTime: 2024-07-29 18:46:22
 */
export default {
	// HOST:"https://rongdun.yunqizhongguo.com/",//接口测试域名
	// HOST:"http://rongdunnw.yunqizhongguo.com:38080/",//接口测试域名
	HOST:"https://www.ynrdjy.cn/",//接口正式域名
}