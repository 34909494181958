/*
 * @Autor: Baowang
 * @Date: 2024-04-23 14:31:28
 * @pageName: 配置文件
 * @LastEditTime: 2024-06-26 18:59:29
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './utils/rem.js'
import disPublic from './utils/disPublic'
import request from './utils/request'
Vue.prototype.$api = request

Vue.prototype.$http = disPublic
Vue.config.productionTip = false
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    eventHub: new Vue()
  }
}).$mount('#app')
