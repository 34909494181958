/*
 * @Autor: Baowang
 * @Date: 2024-04-23 14:31:28
 * @pageName: vuex状态资源管理器
 * @LastEditTime: 2024-05-20 14:42:16
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    typeHome:"home",//全局的头部
    statisticsIndex:0,//统计的索引
    studentsIndex:0,//学习中心索引
    isSearch:false,
    token:"",
    isGuanggaoes:true,//是否显示广告
    searchKeyword:"",//搜索字段
  },
  getters: {
  },
  mutations: {
    setToken:(state,param)=>{
      // 修改token的值
      state.token=param
    },
    setGuanggao:(state,param)=>{
      //修改广告的值
      state.isGuanggaoes=param 
    },
    setType:(state,param)=>{
      // 修改导航的值
      state.typeHome = param
    },
    setStatiscsindex:(state,param)=>{
      state.statisticsIndex=param
    },
    setStudents:(state,param)=>{
      // 设置学员中心的索引
      state.studentsIndex=param
    },
    setSearch(state,param){
      // 是否开启搜索
      state.isSearch=param
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[createPersistedState()]
})
