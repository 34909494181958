/*
 * @Autor: Baowang
 * @Date: 2023-07-04 18:31:22
 * @pageName: 
 * @LastEditTime: 2024-07-23 14:25:10
 */
import { Message } from 'element-ui';
import router from '@/router'
import store from '@/store';
export const publicFunction = {
	testphone: new RegExp(/^1(3|4|5|6|7|8|9)[0-9]{9}$/), //验证手机号
	testpass: new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,18}/), //验证密码 字母数字、特殊字符
	testpassword: new RegExp(/^[\w]{6,18}$/), //验证密码 6-18位 字母、数字和下划线
	testcard: new RegExp(/^(\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/), //身份证
	noClick: true,
	imageArray: [], //上传图片数组
	mapH5: "BJOBZ-3XL6P-WIUDN-LJGSB-PNSP6-OZFTT",
	toast: function(titles) {
		Message({
            showClose: true,
            message: titles,
            type: 'warning',
			duration:1500,
			customClass:"messageClass",
			offset: window.screen.height / 2
          })
	},
	navRouter:function(pathurl,params,isToken){
		// console.log('7788888888',{path:pathurl,query:params})
        if(isToken){
			// 判断是否存在token
			if(store.state.token){
				router.push({path:pathurl,query:params})
			}else{
				// 跳转登录
				router.push({path:'/login'})
			}
		}else{
			router.push({path:pathurl,query:params})
		}
	},
    noMultipleClicks: function(methods, title) {
        // 处理多次点击事件
		let that = this;
		if (that.noClick) {
			that.noClick = false;
			methods();
			setTimeout(function() {
				that.noClick = true;
			}, 3000)
		} else {
			console.log("请稍后点击")
		}
	},
	windowOpen:function(url){
		window.open(url,'_self')
	},
	newwindowOpen:function(url){
		console.log(url,'8999999999999999999')
		window.open(url)
	},
	onCopy(e){
		//创建一个input框
		const input = document.createElement("input")
		//将指定的DOM节点添加到body的末尾
		document.body.appendChild(input)
		//设置input框的value值为直播地址
		input.setAttribute("value", e)
		//选取文本域中的内容
		input.select()
		//copy的意思是拷贝当前选中内容到剪贴板
		//document.execCommand（）方法操纵可编辑内容区域的元素
		//返回值为一个Boolean，如果是 false 则表示操作不被支持或未被启用
		if (document.execCommand("copy")) {
		document.execCommand("copy")
		}
		//删除这个节点
		document.body.removeChild(input)
    }

}
export default publicFunction;