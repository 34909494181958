<!--
 * @Autor: Baowang
 * @Date: 2024-04-23 14:31:28
 * @pageName: 主页面路径
 * @LastEditTime: 2024-06-26 19:04:25
-->
<template>
  <div id="app">
    <!-- 头部区域 -->
    <navheader />
    <!-- 头部占位符 -->
    <div class="nav_zhanwei"></div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- 底部区域 -->
  </div>
</template>
<script>
import navheader from "./components/navHeader/navHeader";
export default {
  components: {
    navheader,
  },
  data() {
    return {};
  },
  // 禁止web端屏幕缩放
  created() {
    window.addEventListener(
      "mousewheel",
      function (event) {
        if (event.ctrlKey === true || event.metaKey) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  },
};
</script>

<style lang="less">
/* 隐藏全局滚动条 */
::-webkit-scrollbar {
  display: none;
}

/* 对于IE和Edge */
body {
  -ms-overflow-style: none; /* Windows Edge, IE 浏览器的滚动条隐藏 */
  scrollbar-width: none; /* Firefox 浏览器的滚动条隐藏 */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .nav_zhanwei {
    width: 100%;
    height: 152px;
  }
}
.messageClass {
  font-size: 30px;
  background-color: #3170ff !important;
  border: 1px solid #3170ff !important;
  .el-message__icon {
    display: none;
  }
  .el-message__content {
    font-size: 22px;
    color: #fff !important;
  }
  .el-message__closeBtn {
    color: #fff;
  }
}
</style>
