<!--
 * @Autor: Baowang
 * @Date: 2024-04-23 15:16:00
 * @pageName: 头部组件
 * @LastEditTime: 2024-06-26 19:11:29
-->
<template>
  <div class="navHeader">
    <div class="navHeader_centent">
      <div class="navHeader_one">
        <div class="navHeader_one_left">
          <el-image class="right_logo" :src="aboutInfo.applogo"> </el-image>
          <div class="navHeader_one_name">{{ aboutInfo.company }}</div>
        </div>
        <div class="navHeader_one_right">{{ aboutInfo.pc_info }}</div>
      </div>
      <!-- 导航切换页面 -->
      <div class="navHeader_two">
        <div class="navHeader_two_left">
          <div
            class="left_one"
            :class="{ left_oneActive: typeHome == items.type }"
            v-for="(items, index) in navArray"
            :key="index"
            @click.stop="goNavhome(items)"
          >
            {{ items.name }}
          </div>
        </div>
        <div
          class="navHeader_two_right"
          v-if="!isSearch"
          @click="$http.navRouter('/homeSearch', '', true)"
        >
          <el-input class="input_one" placeholder="请输入内容">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <div class="input_confirm">搜索</div>
        </div>
        <div class="navHeader_two_right" v-if="isSearch">
          <el-input class="input_one" placeholder="请输入内容" v-model="input4">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <div class="input_confirm" @click="goSearch">搜索</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      navArray: [
        {
          name: "首页",
          type: "home",
          pathName: "/",
          isToken: false,
        },
        {
          name: "课程购买",
          type: "buy",
          pathName: "/buyCourse",
          isToken: true,
        },
        {
          name: "新闻中心",
          type: "news",
          pathName: "/newsList",
          isToken: false,
        },
        {
          name: "关于我们",
          type: "about",
          pathName: "/about",
          isToken: false,
        },
      ],
      input4: "",
      aboutInfo: "",
    };
  },
  computed: {
    ...mapState(["typeHome", "isSearch"]),
  },
  mounted() {
    // console.log("89999999", this.typeHome);
    this.getAbout();
  },
  methods: {
    goSearch() {
      // console.log(this.input4, "搜索内容为4");
      this.$root.eventHub.$emit("eventName", this.input4);
    },
    goNavhome(items) {
      // console.log(items, "89999999", this.$store.state.typeHome);
      // 修改state里面的值
      this.$store.commit("setType", items.type);
      // this.$router.push(items.pathName);
      this.$http.navRouter(items.pathName, {}, items.isToken);
    },
    getAbout() {
      this.$api({
        url: "/index.php/api/common/about",
        method: "POST",
        data: {},
      }).then((res) => {
        // console.log(res, "关于我们");
        this.aboutInfo = res.data;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.navHeader {
  width: 100vw;
  min-height: 100px;
  background-color: #ffffff;
  border-bottom: 1px solid #f7f7f7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  .navHeader_centent {
    width: 1200px;
    .navHeader_one {
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 4px 0 0;
      .navHeader_one_left {
        display: flex;
        align-items: center;
        .right_logo {
          width: 85px;
          height: 85px;
          border-radius: 50%;
        }
        .navHeader_one_name {
          font-family: Source Han Sans CN;
          font-weight: 600;
          font-size: 24px;
          color: #333333;
          margin-left: 25px;
        }
      }
      .navHeader_one_right {
        box-sizing: border-box;
        padding-right: 30px;
        font-weight: 600;
        font-size: 22px;
        color: #3170ff;
      }
    }
    .navHeader_two {
      width: 1200px;
      // background: red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      .navHeader_two_left {
        cursor: pointer; //鼠标手势
        caret-color: transparent; //取消选择的光标
        display: flex;
        align-items: center;
        .left_one {
          width: 169px;
          height: 51px;
          line-height: 51px;
          text-align: center;
          background: #fafafa;
          border: 1px solid #e6e6e6;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
        }
        .left_oneActive {
          background: #3170ff;
          color: #ffffff;
        }
      }
      .navHeader_two_right {
        display: flex;
        align-content: center;
        .input_one {
          width: 416px;
          height: 51px;
          background: #ffffff;
          box-sizing: border-box;
          border: 2px solid #3170ff;
        }
        /deep/ .el-input__inner {
          height: 48px;
          line-height: 48px;
          border: none;
          font-size: 14px;
        }
        .input_confirm {
          width: 80px;
          height: 51px;
          line-height: 51px;
          text-align: center;
          background: #3170ff;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          cursor: pointer;
          caret-color: transparent;
        }
        .input_confirm::before {
          background: red;
        }
      }
    }
  }
}
</style>
